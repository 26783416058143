<template>
  <div>
    <div class="img" />
    <div class="main">
      <div class="tab">
        <div class="map">
          <img src="../../assets/img/位置.png" alt="">
          <div>
            <span class="text1">上海仁信保险经纪有限公司</span>
            <span class="text2">
              <p>地址：</p>
              <p>上海市宝山区沪太路3100号2号楼D区东侧四层D429</p>
            </span>
          </div>
        </div>
        <div class="phone">
          <p>联系方式</p>
          <!-- <div><span>联系电话：021-56482009</span><span>传真号码：021-1232323</span><span></span></div> -->
          <div><span>邮箱地址：zhangmengjun@rxib.com.cn</span><span>邮政编码：200444</span></div>
          <div><span>联系电话：021-56482009</span><span>公司地址：上海市宝山区沪太路3100号2号楼D区东侧四层D429</span></div>
        </div>
      </div>
    </div>
    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有 <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      html: 'weqweq<br/>eqwuihgeuiqwhi'
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  height: 180px;
  background: url("../../assets/img/img联系我们.png") no-repeat center;
}
.main {
  background: #f4f5f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 80px;
  .tab {
    width: 1180px;
    height: 650px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    .map {
      background: url("../../assets/img/map.png") no-repeat;
      background-size: 100% 100%;
      width: 980px;
      height: 380px;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 48%;
        transform: translate(-50%, -50%);
      }
      div {
        background: url("../../assets/img/bg地址.png");
        width: 390px;
        height: 150px;
        transform: translate(21%, 76%);
        display: flex;
        flex-direction: column;
        padding: 39px 37px;
        .text1 {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: 14px;
        }
        .text2 {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          display: flex;
          p {
            min-width: 50px;
            max-width: 240px;
          }
        }
      }
    }
    .phone {
      width: 980px;
      display: flex;
      flex-direction: column;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
        height: 77px;
        line-height: 77px;
        border-bottom: solid 1px #eaeaea;
        margin-bottom: 30px;
      }
      div {
        display: flex;
        margin-bottom: 18px;
        span {
          flex: 1;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
.foot {
  height: 64px;
  background: #393d41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b1a9a9;
}
</style>
